* {
  margin: 0;
  letter-spacing: .5px;
}

html, body, #root {
  min-height: calc(100vh - 86px);
}

body {
  margin: 0;
  font-family: 'Dosis', sans-serif;
  font-size: 1.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  --coral: #e64c25;
  --dark-coral: #c43f1e;
  --aqua: #3cbdac;
  --dark-aqua: #33a092;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  margin-bottom: 50px;
}

a {
  color: var(--aqua);
}

.btn:focus, .btn:active {
  box-shadow: none !important;
}

.coral-button, .coral-button:disabled {
  border-color: var(--coral);
  background-color: var(--coral);
  color: white;
}

.aqua-button, .aqua-button:disabled {
  border-color: var(--aqua);
  background-color: var(--aqua);
  color: white;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.home {
  width: 100vw;
  margin-top: 86px;
  animation-name: fadeIn;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}

.carousel-control-prev, .carousel-control-next {
  width: 10%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators li {
  background-color: #000;
}

.home h1 {
  margin-bottom: 0px;
}

.home-section {
  width: 100vw;
  min-height: calc(100vh - 86px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.home-section.left-text {
  padding-left: 8%;
  justify-content: flex-start;
}

.home-section.right-text {
  padding-right: 8%;
  justify-content: flex-end;
}

@keyframes fadeInText {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.home-section-text {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: min(515px, 80vw);
  animation-name: fadeInText;
  animation-duration: 600ms;
  animation-timing-function: ease-in-out;
}

.home-section-text-box {
  padding: 30px 40px 30px 40px;
  color: #212529;
}

.home-section-text-box:visited, .home-section-text-box:active, .home-section-text-box:focus {
  color: #212529;
  text-decoration: none;
}

.slide-1 .home-section-text-box {
  width: min(515px, 80vw);
}

.artist-quote {
  font-style: italic;
  margin-top: 20px;
  margin-bottom: 0px;
}

.page-content {
  position: relative;
  margin-top: 86px;
  padding: 9vh 8vw 9vh 8vw;
  min-height: calc(100vh - 86px);
  margin-bottom: -80px;
}

.page-content:after {
  content: "";
  display: block;
  height: 80px;
}

.category-banner {
  border-bottom: solid 1px #eee;
  width: 100vw;
  height: 400px;
  min-height: 50vh;
  margin: -9vh -8vw -9vh -8vw;
  margin-bottom: 60px;
  background-size: cover;
  background-position: center;
}

@keyframes darkenBackground {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 17;
  background-color: transparent;
  display: none;
}

.background-overlay.visible {
  animation-name: darkenBackground;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  display: block;
  background-color: rgba(0, 0, 0, 0.7);
}

.quickview {
  position: fixed;
  background-color: white;
  z-index: 18;
  display: none;
  border-radius: 4px;
  overflow: hidden;
  width: 302px;
  margin: 0;
}

.quickview.expanded {
  display: flex;
  overflow-y: auto;
  animation-name: expandQuickview;
  animation-duration: 900ms;
  animation-timing-function: ease-in-out;
  top: 0;
  left: 0;
  max-height: 80vh;
}

@keyframes fadeOutQuickview {
  0% { opacity: 1; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.quickview.fade-out {
  animation-name: fadeOutQuickview;
  animation-duration: 500ms;
  opacity: 0;
}

.quickview .carousel {
  width: 300px;
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.quickview .carousel .carousel-inner {
  border-radius: 4px;
}

.quickview .hidden-carousel {
  visibility: hidden;
}

.quickview .zoomed-carousel {
  width: auto;
  max-width: 80vw;
  max-height: 80vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

.zoomed-carousel-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 19;
}

.quickview-img {
  border-radius: 4px;
  width: 300px;
}

.zoomed-carousel .quickview-img {
  width: auto;
  max-width: 80vw;
  max-height: 80vh;
}

@keyframes expandQuickviewImg {
  0% {
    margin: 0;
  }
  100% {
    margin: 15px;
  }
}

.quickview-img-container {
  margin: 0;
}

.quickview-img-container.expanded {
  animation-name: expandQuickviewImg;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  margin: 15px;
}

.exit-quickview {
  position: absolute;
  top: 12px;
  right: 20px;
  width: 20px;
  color: white;
}

.item-details-container {
  text-align: left;
  width: 325px;
  max-height: 2000px;
  padding: 15px;
}

@keyframes fadeInDetails {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.thumbnails-placeholder {
  width: 240px;
}

.thumbnails-outer-container {
  position: relative;
}

.thumbnails-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  max-height: 2000px;
  width: 240px;
}

.thumbnail {
  width: 100px;
  margin: 5px;
  padding: 0px;
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.thumbnail-img {
  width: 98px;
  border-radius: 4px;
}

.item-dimensions, .item-materials {
  display: flex;
  align-items: center;
}

.item-details-icon {
  width: 20px;
  margin-right: 15px;
  margin-bottom: 1rem;
}

.item-price-container {
  margin-bottom: 0px;
}

.item-price-container.without-colors {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.item-price-container.without-colors .item-price {
  margin-right: 10px;
  margin-bottom: 15px;
}

.item-price-container .btn {
  margin-bottom: 15px;
}

.color-dropdown {
  width: 175px;
  margin-bottom: 10px;
}

.masonry-layout {
  margin: 0 auto;
}

.masonry-layout.hidden {
  opacity: 0;
}

.thumbnails-container.hidden, .item-details-container.hidden {
  opacity: 0;
}

.masonry-layout.visible {
  opacity: 1;
  animation-name: fadeIn;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}

.thumbnails-container.visible, .item-details-container.visible {
  opacity: 1;
  animation-name: fadeIn;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}

@keyframes wave {
	0%, 60%, 100% {
    transform: initial;
	}
	30% {
		transform: translateY(-10px);
	}
}

.loading-dots {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loading-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 4px;
  background:rgba(0, 0, 0, 0.5);
  animation: wave 1.3s linear infinite;
}

.loading-dot.second {
  animation-delay: -1.1s;
}

.loading-dot.third {
  animation-delay: -0.9s;
}

.items-container {
  position: relative;
}

.item {
  position: relative;
  width: 300px;
  margin: 20px;
  padding: 0px;
}

.item-img {
  width: 300px;
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.quickview-button-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0s;
  border-radius: 4px;
}

.quickview-button-container .btn {
  border: solid rgba(0, 0, 0, 0.5) 1px;
  background-color: rgba(248, 249, 250, 0.9);
}

.footer {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer p {
  font-size: 14px;
  margin-bottom: 0px;
}

.footer a {
  text-decoration: underline;
  z-index: 10;
}

.bag-icon-container {
  position: relative;
  width: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-home-link {
  height: 60px;
  transition: all 200ms;
  transition-timing-function: ease-in-out;
}

.navbar-light {
  z-index: 16;
  background-color: white;
  border-bottom: solid 1px #eee;
}

.navbar-light .navbar-toggler {
  border: none;
  outline: none;
  padding-right: 0px;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 15px;
}

.navbar-dropdown-header {
  color: rgba(0, 0, 0, 0.5);
}

.dropdown-item.active {
  background-color: white;
  color: rgba(0, 0, 0, 0.9);
}

.dropdown-item:not(.active) {
  color: rgba(0, 0, 0, 0.5);
}

.nav-link {
  margin-left: 7px;
  margin-right: 7px;
}

.nav-link.active > .bag-icon-container .bag-icon path {
  stroke: rgba(0, 0, 0, 0.9);
}

.nav-link.active > .bag-icon-container .bag-icon circle {
  stroke: rgba(0, 0, 0, 0.9);
}

.checkout-header {
  margin-bottom: 30px;
}

.checkout-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.shopping-bag-details {
  min-width: 100%;
}

.shopping-bag-details.collapsed {
  min-width: 0px;
  max-width: 400px;
  flex-grow: 1;
}

.bag-number-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bag-number {
  font-size: 11px;
  margin-top: 7px;
}

.bag-total {
  margin-top: 30px;
  margin-bottom: 20px;
}

.bag-total span:first-of-type {
  margin-right: 10px;
}

.shipping-note {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.shipping-note p:first-of-type {
  margin-bottom: 0px;
}

.bio {
  text-align: left;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.bio img {
  width: 300px;
  max-width: 100%;
  float: left;
  margin-right: 50px;
  margin-bottom: 20px;
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.about-page, .contact-page {
  background-color: #eee;
}

.contact form {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.contact h1 {
  margin-bottom: 15px;
}

.contact p:first-of-type {
  margin-bottom: 25px;
}

.form-control {
  font-size: 1.2rem;
}

.form-control:focus {
  border-color: var(--aqua);
  box-shadow: 0 0 0 0.2rem rgba(51, 160, 146, .25);
}

.checkout-form .card-field {
  margin-bottom: 15px;
  background-color: white;
  padding: 11px 16px;
  border-radius: 6px;
  border: 1px solid #CCC;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  line-height: 1.3333333;
}

.checkout-button {
  margin-top: 10px;
}

.checkout-button.hidden {
  display: none;
}

.checkout-form-container {
  padding-top: 20px;
}

.checkout-form-container.hidden {
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.checkout-form-container.visible {
  width: auto;
  height: auto;
}

.checkout-form .card-field.StripeElement--focus {
  border-color: var(--aqua);
  box-shadow: 0 0 0 0.2rem rgba(51, 160, 146, .25);
}

.checkout-form .form-row {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: solid 1px #ced4da;
}

.checkout-form .form-row * {
  margin-bottom: 0;
}

.checkout-form .form-row.focused {
  border-color: var(--aqua);
  box-shadow: 0 0 0 0.2rem rgba(51, 160, 146, .25);
}

.checkout-form .form-row.focused * .form-control {
  box-shadow: none;
  border-color: transparent;
}

.checkout-form .city-input {
  width: 50%;
}

.checkout-form .address-line-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.checkout-form .address-line-2 .form-control {
  border: none;
}

.checkout-form .state-input {
  width: 85px;
}

.checkout-form .state-input.placeholder .form-control {
  color: rgba(0, 0, 0, 0.5);
}

.checkout-form .zip-input {
  width: calc(50% - 85px);
  min-width: 85px;
}

.checkout-success .modal-header {
  border-bottom: none;
}

.checkout-success .modal-header button.close:focus {
  outline: none;
}

.checkout-success .modal-body {
  padding-top: 0px;
  padding-bottom: 60px;
}

.payment-note {
  margin-top: 15px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.payment-note .fa-lock {
  margin-right: 5px;
}

.sort-by {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.sort-by-dropdown {
  width: 175px;
  margin-left: 10px;
}

@media all and (max-width: 1024px) {
  .home-section-text-box {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

@media all and (max-width: 991px) {
  .navbar-light .navbar-brand {
    margin-left: 0px;
  }

  .navbar-dropdown-header {
    text-align: center;
  }

  .dropdown-item {
    text-align: center;
  }

  .home {
    margin-top: 54px;
  }

  .home-section {
    min-height: calc(100vh - 54px);
  }

  .page-content {
    margin-top: 54px;
    min-height: calc(100vh - 54px);
  }
}

@media all and (min-width: 992px) {
  .navbar-dropdown-category {
    padding-left: 40px;
  }

  .navbar-dropdown-header {
    padding: .25rem 1.5rem .25rem 1.5rem;
  }
}

@media all and (min-width: 601px) {
  .home-section.slide-3 .home-section-text-box {
    margin-bottom: 330px;
  }
  
  .home-section.slide-4 .home-section-text-box {
    margin-top: 150px;
  }
  
  .home-section.slide-5 .home-section-text-box {
    margin-bottom: 200px;
  }
}

@media all and (max-width: 600px) {
  .home h1 {
    font-size: 36px;
  }

  .home-section.left-text {
    padding-left: 0px;
    justify-content: center;
  }
  
  .home-section.right-text {
    padding-right: 0px;
    justify-content: center;
  }

  .bio img {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 40px;
  }

  .page-content {
    padding-top: 10vh;
  }

  .category-banner {
    margin-top: -10vh;
  }

  h1 {
    margin-bottom: 35px;
  }
}

@media all and (min-width: 600px) {
  @keyframes shiftTagline {
    0% {
      margin-left: 0px;
    }
    50% {
      margin-left: 0px;
    }
    100% {
      margin-left: 20px;
    }
  }

  .tagline-line-1 {
    margin-right: 100px;
  }

  .tagline-line-2 {
    animation-name: shiftTagline;
    animation-duration: 1200ms;
    animation-timing-function: ease-in-out;
    margin-left: 20px;
  }
}

@media all and (min-width: 480px) {
  .checkout-form-container form {
    margin: 0 auto;
    max-width: 480px;
  }
}

@media all and (hover: hover) {
  .home-section-text-box:hover {
    color: #212529;
    text-decoration: none;
  }

  a:hover {
    color: var(--dark-aqua);
  }

  .coral-button:hover {
    border-color: var(--dark-coral);
    color: white;
    background-color: var(--dark-coral);
  }

  .aqua-button:hover {
    border-color: var(--dark-aqua);
    color: white;
    background-color: var(--dark-aqua);
  }

  .dropdown-item:hover {
    background-color: white;
    color: rgba(0, 0, 0, 0.9);
  }

  .nav-link:hover > .bag-icon-container .bag-number {
    color: rgba(0, 0, 0, 0.9);
  }

  .nav-link:hover > .bag-icon-container .bag-icon path {
    stroke: rgba(0, 0, 0, 0.9);
  }

  .nav-link:hover > .bag-icon-container .bag-icon circle {
    stroke: rgba(0, 0, 0, 0.9);
  }

  .item:hover {
    cursor: pointer;
  }

  .item:hover .quickview-button-container {
    opacity: 1;
    transition: opacity 200ms;
    transition-timing-function: ease-in-out;
  }
  
  .thumbnail-img:hover {
    cursor: pointer;
  }

  .quickview-img.zoomable:hover {
    cursor: zoom-in;
  }

  .exit-quickview:hover {
    cursor: pointer;
  }

  .zoomed-carousel-background:hover {
    cursor: zoom-out;
  }
}